// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/packs and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import "@rails/ujs"
var Turbolinks = require("turbolinks")
var ActiveStorage = require("@rails/activestorage")
Turbolinks.start()
ActiveStorage.start()
import "bootstrap"
import "@fortawesome/fontawesome-free/css/all"
import "popper.js"

console.log('Hello World from Webpacker')

if (navigator.serviceWorker) {
    navigator.serviceWorker.register('/service-worker.js', { scope: './' })
      .then(function(reg) {
        console.log('[Companion]', 'Service worker registered!');
        console.log(reg);
      });
  }

$( document ).on('turbolinks:load', function() {
  $('.btn-report.disabled').popover({
    trigger: 'focus'
  })
})